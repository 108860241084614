<template>
  <div>
    <v-row>
      <v-col
        v-if="!isLoading.email"
        cols="12"
      >
        <v-alert
          v-if="!error.email"
          type="success"
          style="margin-bottom: 0;"
        >
          Un email vous a été envoyé avec le récapitulatif de votre estimation
        </v-alert>
        <v-alert
          v-else
          type="error"
        >
          {{ error.email }}
        </v-alert>
      </v-col>
      <v-col
        v-else
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </v-col>
      <v-col cols="12">
        <p class="text-center">
          Vous avez réalisé l'estimation de votre bien sur notre site. <br>
          Voici le résultat de votre estimation selon notre méthode comparative. Cette analyse se fonde sur un panel de références dites comparables provenant de notre base de données.
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-card
          color="primary"
          dark
          width="100%"
          height="250px"
          transition="scale-transition"
          class="d-flex flex-column justify-center align-center"
        >
          <v-card-subtitle class="text-center">
            Estimation basse
          </v-card-subtitle>
          <v-card-title class="display-1 text-center">
            {{ estimateResult(selectedPrice, estimate).prixBas }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-card
          color="primary"
          dark
          width="100%"
          height="250px"
          transition="scale-transition"
          class="d-flex flex-column justify-center align-center"
        >
          <v-card-subtitle class="text-center">
            Estimation moyenne
          </v-card-subtitle>
          <v-card-title class="display-1 text-center">
            {{ estimateResult(selectedPrice, estimate).prixMoyen }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-card
          color="primary"
          dark
          width="100%"
          height="250px"
          transition="scale-transition"
          class="d-flex flex-column justify-center align-center"
        >
          <v-card-subtitle class="text-center">
            Estimation haute
          </v-card-subtitle>
          <v-card-title class="display-1 text-center">
            {{ estimateResult(selectedPrice, estimate).prixHaut }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12">
        <p>
          <strong>Il reste cependant indispensable que nous réalisions une expertise qualitative de votre bien.</strong><br>
          A cette fin, un conseiller de TERRASSE EN VILLE et BEAULIEU EN VILLE va prendre contact avec vous dans les 24 heures afin de prendre un rendez-vous.
        </p>
      </v-col>
      <v-col cols="6">
        <v-btn
          width="100%"
          @click="update"
        >
          <v-icon>{{ icons.mdiChevronLeft }}</v-icon> Modifier
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          width="100%"
          @click="reset"
        >
          <v-icon>{{ icons.mdiAutorenew }}</v-icon> Recommencer
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

import {
  mdiChevronLeft,
  mdiAutorenew
} from '@mdi/js'

export default {
  name: 'EstimateResult',
  data () {
    return {
      icons: {
        mdiChevronLeft,
        mdiAutorenew
      }
    }
  },
  computed: {
    ...mapState([
      'isLoading',
      'error'
    ]),
    ...mapState(['estimate', 'selectedPrice']),
    ...mapGetters(['estimateResult'])
  },
  mounted () {
    this.$gtag.event('conversion', { send_to: 'AW-1037442492/W_uvCM2r5ogDELy72O4D' })
    this.$gtag.event('conversion', { send_to: 'AW-1037442492/wzkxCJnUp6kDELy72O4D' })
    this.sendEmail()
  },
  methods: {
    ...mapActions(['sendEmail', 'resetEstimate']),
    reset () {
      this.resetEstimate()
      this.$router.push({ name: 'home' })
    },
    update () {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
