<template>
  <v-container fill-height>
    <estimate-result />
  </v-container>
</template>

<script>
import EstimateResult from '../components/EstimateResult.vue'

export default {
  name: 'EstimateResultPage',
  components: {
    EstimateResult
  }
}
</script>
